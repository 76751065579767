import * as React from 'react'
import { graphql, navigate } from 'gatsby'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import Spinner from 'react-spinner-material'
import Sidebar from '../components/sidebar'
import Layout from '../components/layout'
import Seo from '../components/seo'
import PageHeader from '../components/pageheader'
import dinnerimg from '../assets/images/familydinner.jpg'
import { Grid } from '@mui/material'

const BASE_API = "https://cduun4s5kc.execute-api.ap-southeast-2.amazonaws.com/v1/"
const MOBILE_BREAKPOINT = 721
const isClient = typeof window !== 'undefined'
const styles = {
  fontFamily: "sans-serif",
  textAlign: "center"
};
class ThankyouPage extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  }
  constructor(props) {
    super(props)
    this.state = {
      viewportWidth: isClient ? window.innerWidth : 0,
      loading: true,      
      orderitems: [],
    }
    this.changeCookie = this.changeCookie.bind(this);
    this.switchPage = this.switchPage.bind(this);
    this.sendorderdetails = this.sendorderdetails.bind(this);
  }
  switchPage(pagename){
    this.setState({
        loading: true,
     });
     navigate(pagename)
  }; 
  changeCookie(cookieName, newState) {
    const { cookies } = this.props
    cookies.set(cookieName, newState, { path: '/' })
    this.setState({ cookieName: newState })
  }
  updateWindowDimensions = () => {
    // console.log(window.innerWidth)
    this.setState({ viewportWidth: window.innerWidth })
  }
  sendorderdetails = async (event) => {
    // console.log(this.props.allCookies)
    
    var newOrderDetails = {
      name: this.props.allCookies.userdetails.name,
      mobile: this.props.allCookies.userdetails.mobile,
      email: this.props.allCookies.userdetails.email,
      orderitems: this.props.allCookies.orderitems
    }
    // console.log(JSON.stringify(newuserdetails))
    let headers = new Headers()
    headers.append('Content-Type', 'text/html')
    headers.append('Origin', '*')
    var requestConfig = {
      method: 'POST',
      body: JSON.stringify(newOrderDetails),
      mode: 'cors',
      cache: 'no-cache',
      headers: headers,
    }
    try {
      fetch(BASE_API + 'processorder', requestConfig)
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        // console.log(true)
      })
      .catch((error) => {
        console.log(error)
      })
      return true
    } catch(err) {
      console.log(err);
      return false
    }
    
  }
  componentDidMount() {    
    this.changeCookie('lastpage', '/')
    // if (!this.props.allCookies.firstload) {            
    //   setTimeout(() => {
    //     navigate('/loading')
    //   }, 500)
    // }
    if (isClient) {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }
    this.setState({
        orderitems: []        
    })
    if(this.sendorderdetails()) {
      this.changeCookie('orderitems', [])
    } 
    
    this.setState({ loading: false })
  }
  componentWillUnmount() {
    if (isClient)
      window.removeEventListener('resize', this.updateWindowDimensions)
  }

  render() {
    const siteTitle = "Ready Made Meals"
    const keywords = ["ready made meals", "healthy", "dinner", "family", "building futures", "montessori"]
    const pageName = 'Thank-you'    

    var textcol = 6
    var imagecol = 4
    var spacercol = 2    
    
    const { viewportWidth } = this.state
    const isMobile = Boolean(viewportWidth <= MOBILE_BREAKPOINT)
    var pagewidth = {
      matches: false,
      media: '(max-width: 600px)',
      onchange: null,
    }
    if (typeof window !== 'undefined') {
      pagewidth = window.matchMedia('(max-width: 600px)')
    }
    if (isMobile) {
      textcol = 12
      imagecol = 12
      spacercol = 0
    }

    return (
      <React.Fragment>
        <Seo pagename={pageName + ' ' + siteTitle} keywords={keywords} />
        <Layout data={this.props} pagewidth={pagewidth.matches}>
          <Sidebar switchPage={this.switchPage} orderitems={this.state.orderitems} removeitemfromorder={this.removeitemfromorder}/>
          {this.state.loading ? (
            <div className="spinneroverlay">
              <div className="spinnercontainer">
                <Spinner
                  radius={100}
                  color={'#ff4c76'}
                  stroke={2}
                  visible={true}
                />
              </div>
            </div>
          ) : (
            <div />
          )}
          
          <div className="page_wrapper generalpage_wrapper">
            <PageHeader pagewidth={pagewidth.matches} pagetitle={pageName} />
            <Grid container spacing={0} >
              <Grid item xs={12} >
                <Grid container spacing={0} >
                  <Grid item xs={1} />
                  <Grid item xs={10} >
                    <div className='generalpage_content'>                      
                      <div className="contentcontainer">                      
                        <Grid container spacing={0}>
                          <Grid item xs={imagecol} className="contentimagecontainer">
                            <img className="contentimage" src={dinnerimg} />
                          </Grid>
                          <Grid item xs={textcol}>
                            <div className="content_text">
                              Thank-you for ordering Ready Made Meals. You will shortly receive an 
                              email outlining your order, if there are any problems please don't 
                              hesitate to contact us on the details provided. 
                            </div>
                            <div className="content_text">
                              We hope you enjoy our delicious meals and that they have helped to
                              make your week just a little easier. We look forward to next time 
                              you order, Ready Made Meals.
                            </div>
                          </Grid>
                          <Grid item xs={spacercol} />
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}

export default withCookies(ThankyouPage)
